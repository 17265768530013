<template>
  <v-card flat>
    <v-card-title class="pa-0">
      <v-tabs
        background-color="grey lighten-4"
        :disabled="table.loading"
        mandatory
        slider-color="primary"
        v-model="tab"
        height="56"
      >
        <v-tab class="body-2 font-weight-bold text-capitalize" href="#reminders">
          <v-badge color="green" :value="table.reminders.length" :content="table.reminders.length">
            <v-icon size="18" left>mdi-bell-ring</v-icon>
            {{ $t("dashboard.reminder_tab_title") }}
          </v-badge>
        </v-tab>
        <v-tab class="body-2 font-weight-bold text-capitalize" href="#messages">
          <v-badge color="green" :value="table.messages.length" :content="table.messages.length">
            <v-icon size="18" left>mdi-chat-processing</v-icon>
            {{ $t(`dashboard.message_tab_title`) }}
          </v-badge>
        </v-tab>
      </v-tabs>
    </v-card-title>
    <v-card-text class="pa-0">
      <v-tabs-items v-model="tab" mandatory :disabled="table.loading">
        <v-tab-item value="reminders">
          <template v-if="table.loading">
            <v-card flat height="346">
              <v-card-text class="fill-height pa-0">
                <v-container fill-height justify-center align-center>
                  <v-layout justify-center align-center>
                    <v-progress-circular indeterminate size="60" color="grey lighten-3" />
                  </v-layout>
                </v-container>
              </v-card-text>
            </v-card>
          </template>
          <template v-else>
            <v-data-table
              v-if="table.reminders.length"
              class="request-table"
              :items="table.reminders"
              :loading="table.loading"
              :no-data-text="table.loading ? '' : $vuetify.noDataText"
              :footer-props="{ itemsPerPageOptions: [5] }"
              hide-default-header
            >
              <template #item="row">
                <tr>
                  <td width="2%">
                    <employee-profile :employee="row.item" />
                  </td>
                  <td>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-medium">{{
                        (row.item.employment.branch || {}).name || "-"
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="caption">{{
                        (row.item.employment.unit || {}).name || "-"
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </td>
                  <td>
                    <v-icon>
                      {{ row.item.type === "BIRTHDATE" ? "mdi-cake-variant" : "mdi-balloon" }}
                    </v-icon>
                    <span v-if="row.item.type === 'BIRTHDATE'">
                      {{ $t("global.birth_date") }}
                    </span>
                    <span v-else-if="row.item.type === 'HIRING'">
                      {{ getHiring(row.item) }}
                    </span>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <template v-else>
              <v-card flat height="346">
                <v-card-text class="fill-height pa-0">
                  <v-container fill-height justify-center align-center>
                    <v-layout justify-center align-center>
                      {{ $t("dashboard.messages.no_data_reminders") }}
                    </v-layout>
                  </v-container>
                </v-card-text>
              </v-card>
            </template>
          </template>
        </v-tab-item>
        <v-tab-item value="messages">
          <template v-if="table.loading">
            <v-card flat height="346">
              <v-card-text class="fill-height pa-0">
                <v-container fill-height justify-center align-center>
                  <v-layout justify-center align-center>
                    <v-progress-circular indeterminate size="60" color="grey lighten-3" />
                  </v-layout>
                </v-container>
              </v-card-text>
            </v-card>
          </template>
          <template v-else>
            <v-data-table
              v-if="table.messages.length"
              class="request-table"
              :items="table.messages"
              :loading="table.loading"
              :no-data-text="table.loading ? '' : $vuetify.noDataText"
              :footer-props="{ itemsPerPageOptions: [5] }"
              hide-default-header
            >
              <template #item="row">
                <tr>
                  <td width="2%">
                    <employee-profile :employee="row.item.sender" />
                  </td>

                  <td>
                    <v-list-item-content>
                      <v-list-item-title>{{ row.item.title }}</v-list-item-title>
                    </v-list-item-content>
                  </td>
                  <td>
                    {{ row.item.timestamp | formattedDateTime }}
                  </td>
                </tr>
              </template>
            </v-data-table>
            <template v-else>
              <v-card flat height="346">
                <v-card-text class="fill-height pa-0">
                  <v-container fill-height justify-center align-center>
                    <v-layout justify-center align-center>
                      {{ $t("dashboard.messages.no_data_messages") }}
                    </v-layout>
                  </v-container>
                </v-card-text>
              </v-card>
            </template>
          </template>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
  import { DASHBOARD_REMINDERS } from "../query";

  export default {
    name: "reminders",
    data: vm => ({
      tab: "reminders",
      criteria: {
        manager: null,
        beginDate: vm.$moment().format("YYYY-MM-DD"),
        endDate: vm.$moment().format("YYYY-MM-DD")
      },
      table: {
        loading: false,
        reminders: [],
        messages: [],
        headers: []
      }
    }),
    methods: {
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: DASHBOARD_REMINDERS,
            variables: this.criteria
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              const { birthDates, hiringDates, messages } = data.dashboard;
              const birth_dates = birthDates.map(item => {
                return {
                  ...item,
                  type: "BIRTHDATE"
                };
              });
              const hiring_dates = hiringDates.map(item => {
                return {
                  ...item,
                  type: "HIRING"
                };
              });
              this.table.reminders = [...birth_dates, ...hiring_dates];
              this.table.messages = messages;
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => {
            this.table.loading = false;
          });
      },
      getHiring(item) {
        const birthdate = this.$moment(item.employment.hiredDate);
        const now = this.$moment();
        return now.diff(birthdate, "years") + `. ${this.$t("date.year").toLocaleLowerCase()}`;
      }
    },
    created() {
      this.fetchItems();
    }
  };
</script>

<style scoped lang="scss">
  .request-table::v-deep {
    .v-data-table__wrapper {
      min-height: 320px;
    }
  }
</style>
